import React, { useEffect, useState } from 'react'
import Products from './Products'
import { useSelector } from 'react-redux';
import { getMoonSignSvg } from '../commonFun';

export default function ResultTheme2() {
    const themeSetting = useSelector((state) => state.theme && state.theme.data && state.theme.data.design);
    const resultDetailData = useSelector((state) => state.resultDetail && state.resultDetail.data);
    const resultDetail = resultDetailData.data;

    const [moonSignImg, setMoonSignSvg] = useState('');
    const [moonSignName, setMoonSignName] = useState('');

    
  useEffect(() =>{
    let moonSignName = resultDetail && resultDetail.rashi && resultDetail.rashi.rashi;
    let signColor = themeSetting && themeSetting.form.color;
    let moonSign = getMoonSignSvg(moonSignName,signColor)

    switch (resultDetail.rashi.rashi) {
        case "Aries":
            setMoonSignName('मेष')
            break;
        case "Taurus":
            setMoonSignName('वृषभ')
            break;
        case "Gemini":
        setMoonSignName('मिथुन')
        break;
        case "Cancer":
        setMoonSignName('कर्क')
        break;
        case "Leo":
        setMoonSignName('सिंह')
        break;
        case "Virgo":
        setMoonSignName('कन्या')
        break;
        case "Libra":
        setMoonSignName('तुला')
        break;
        case "Scorpio":
        setMoonSignName('वृश्चिक')
        break;
        case "Sagittarius":
        setMoonSignName('धनु')
        break;
        case "Capricorn":
        setMoonSignName('मकर')
        break;
        case "Aquarius":
        setMoonSignName('कुंभ')
        break;
        case "Pisces":
        setMoonSignName('मीन')
        break;
        default:
            break;
    }
    setMoonSignSvg(moonSign)
  },[resultDetail,themeSetting])
  return (
    <>
        <div className='w-full bg-[#F2EEEA] md:bg-[#fff] my-[50px]' id='resultView'>
            <div className=''>
                <div className='bg-[#F2EEEA] w-[90%] sm:w-[80%] mx-auto py-[60px] sm:px-[80px] rounded-[40px]'>
                    <div className='flex flex-col pb-[30px]' >
                    {
                        themeSetting && themeSetting.resultbox.rashidetails &&
                        <div className='flex'>
                            <div className='flex flex-col items-center'>
                                <svg className='w-[100px] sm:w-[120px]'>
                                    {moonSignImg}
                                </svg>
                                <div style={{color: themeSetting && themeSetting.form.color}} className='text-[#151e44] -mt-[20px] sm:mt-[10px] font-libre-baskerville font-[700]'>{resultDetail && resultDetail.rashi.rashi}</div>
                            </div>
                            <div style={{color: themeSetting && themeSetting.form.color}} className='ms-[15px] text-[20px] mt-[35px] sm:mt-[20px] sm:text-[25px] text-[#004563] '>
                                <div className='font-[700] font-libre-baskerville'>Hello, {resultDetail && resultDetail.name}!</div>
                                <div className='leading-none font-libre-baskerville font-[400]'> Your Moon Sign is <b>{resultDetail && resultDetail.rashi.rashi}</b></div>
                                <div className=' font-libre-baskerville font-[400] mt-[10px]'>आपकी लग्न राशि {moonSignName} है।</div>
                            </div>
                        </div>
                    }
                    </div>
                    
                    {
                        themeSetting && themeSetting.resultbox.userinput &&
                        <div  className='lg:flex justify-between gap-[20px]'>
                        <div style={{boxShadow: '0px 6px 27px -13px black', color: themeSetting && themeSetting.form.color}} className='w-full bg-white text-[14px] md:text-[17px] font-inter border overflow-hidden text-[#004563] rounded-[22px]'>
                                <div className='flex p-[15px] border-b px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-inter'>Email - </span> {resultDetail && resultDetail.email} </div>
                                </div>
                                <div className='flex p-[15px] border-b px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-inter'>Phone - </span> {resultDetail && resultDetail.mobile} </div>
                                </div>
                                {/* <div className='flex p-[15px] px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-inter'>Gender - </span>{resultDetail && resultDetail.gender} </div>
                                </div> */}
                            </div>

                            <div style={{boxShadow: '0px 6px 27px -13px black', color: themeSetting && themeSetting.form.color}} className='w-full bg-white text-[14px] md:text-[17px] font-inter overflow-hidden border text-[#004563] rounded-[22px] mt-[30px] lg:mt-0'>
                                <div className='flex p-[15px] border-b px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-inter'>Date of Birth - </span>{resultDetail && resultDetail.birth_date}</div>
                                </div>
                                {/* <div className='flex p-[15px] border-b px-[20px] sm:px-[40px]'>
                                    <div><span className='font-[500] font-inter'>Body Weight - </span>{resultDetail && resultDetail.body_weight}</div>
                                </div> */}
                                <div className='flex p-[15px] px-[20px] sm:px-[40px]'>
                                    <div className='leading-none'><span className='font-[500] font-inter'>Birth Place - </span>{resultDetail && resultDetail.birth_place}</div>
                                </div>
                            </div>
                        </div>
                    }

                    
            
                </div>
            </div>

        </div>
    </>
  )
}
