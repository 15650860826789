import instance from "../axiosConfig";

export let calculateResult = async(e,records) =>{

    const formDataPre = {
        date: records.date+" "+records.time+":00",
        timezone: "Asia/kolkata",
        name: records.name,
        address: records.place,
        mobile: records.mobile,
        email: records.email,
        gender: records.gender,
        purpose: records.purpose,
        birth_date: records.date,
        birth_time: records.time,
        body_weight: records.bodyWeight,
        birth_place: records.place,
      };
    try {
        // Use the axios instance to make requests
        const response = await instance.post("/moonsign/calculateMoonsign",formDataPre); // The URL will be resolved relative to the baseURL
        if(response.data.gems){
            const data = {
                resultview:true,
                data:response.data
            };
            return data
        }
      } catch (error) {
        //   setError(error);
      } finally {
        //   setLoading(false);
      }
}






export function getMoonSignSvg (signName, themeColor){
    switch (signName) {
        case 'Aries':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 346.000000 346.000000">
                      <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                      fill="white" stroke="none">
                      <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                      <path d="M1060 2896 c0 -2 11 -23 24 -47 37 -67 66 -163 65 -216 -1 -56 -19 -125 -35 -130 -7 -2 -26 31 -44 74 -82 200 -122 293 -126 293 -14 0 -87 -44 -131 -78 -54 -43 -141 -164 -145 -202 -2 -19 8 -30 50 -54 29 -17 51 -35 49 -41 -2 -5 -31 -11 -64 -13 -53 -3 -62 -6 -67 -25 -17 -66 2 -285 31 -341 23 -44 93 -116 138 -139 31 -17 57 -22 120 -22 74 0 88 4 168 42 48 23 87 43 87 45 0 2 -37 2 -82 0 -100 -4 -155 10 -207 54 -70 60 -95 196 -48 265 50 74 132 106 230 89 104 -19 167 -54 167 -92 0 -49 90 14 153 109 47 71 147 267 147 289 0 19 -172 103 -256 124 -65 17 -224 28 -224 16z"/>
                      <path d="M2131 2880 c-78 -19 -261 -105 -261 -122 0 -6 32 -73 72 -151 55 -107 86 -155 133 -204 58 -60 95 -79 95 -49 0 39 101 87 209 102 39 5 55 1 110 -28 83 -43 115 -95 108 -175 -8 -79 -44 -142 -102 -178 -46 -28 -54 -30 -156 -30 l-108 0 37 -23 c140 -85 281 -92 382 -18 98 71 129 157 130 358 l0 117 -67 3 c-38 2 -68 7 -67 13 0 6 24 24 52 40 29 17 52 37 52 45 0 30 -76 143 -129 192 -30 27 -76 60 -102 74 l-48 24 -16 -30 c-8 -16 -43 -96 -76 -177 -33 -81 -64 -152 -70 -158 -14 -14 -26 9 -39 72 -15 69 0 146 45 242 19 40 35 75 35 77 0 11 -155 0 -219 -16z"/>
                      <path d="M1590 2714 c0 -34 -53 -173 -91 -236 -73 -124 -175 -203 -281 -215 -44 -5 -49 -8 -44 -27 3 -12 6 -29 6 -39 0 -17 7 -18 58 -15 31 2 64 8 73 12 14 6 17 -3 22 -59 14 -144 55 -258 107 -297 17 -12 30 -30 30 -40 0 -29 29 -92 50 -108 12 -9 27 -44 37 -85 33 -127 103 -214 163 -200 28 7 76 49 92 80 6 11 -6 2 -25 -20 l-36 -40 -1 35 c0 35 41 110 60 110 5 0 12 -10 16 -22 l7 -23 9 25 c5 14 14 48 20 75 6 28 19 56 29 63 25 17 49 65 49 96 0 17 14 38 40 62 57 53 75 108 106 322 5 32 17 41 29 22 3 -5 33 -10 66 -10 l59 0 0 40 c0 38 -2 40 -30 40 -51 0 -137 39 -191 88 -85 76 -154 200 -189 340 l-11 42 -29 -15 c-37 -19 -127 -19 -164 0 -35 19 -36 19 -36 -1z m-125 -528 c45 -76 53 -136 19 -136 -17 0 -58 62 -74 113 -24 76 14 92 55 23z m545 12 c-1 -62 -62 -160 -89 -143 -25 15 -7 87 35 142 33 43 54 43 54 1z m-387 -646 c19 -21 47 -85 47 -107 0 -28 -20 -15 -50 32 -42 66 -40 122 3 75z"/>
                      <path d="M1976 1649 c-11 -55 -92 -210 -136 -262 -23 -26 -61 -59 -85 -73 -43 -25 -45 -25 -80 -8 -81 38 -189 187 -226 312 -12 41 -29 57 -29 27 0 -33 -43 -143 -81 -207 -22 -37 -65 -95 -96 -129 l-57 -61 12 -68 c27 -160 148 -455 221 -540 l30 -35 1 69 c0 38 3 67 6 63 3 -3 25 -52 48 -109 66 -165 163 -318 201 -318 23 0 101 97 143 177 22 43 54 117 73 166 18 48 36 87 41 87 4 0 8 -30 8 -67 l0 -68 36 50 c76 107 173 346 209 514 l15 73 -52 57 c-90 98 -150 208 -182 333 l-13 51 -7 -34z"/>
                      </g>
                  </svg>         
        case 'Aquarius':
        return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
               viewBox="0 0 346.000000 346.000000">
                  <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                  fill="white" stroke="none">
                  <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                  <path d="M1735 2974 c-11 -2 -45 -9 -75 -15 -125 -25 -282 -112 -371 -206 -280 -295 -349 -535 -195 -682 40 -39 45 -37 91 29 25 36 26 43 15 69 -16 40 -14 116 4 169 37 104 180 262 278 307 188 86 363 69 473 -45 l40 -41 0 -82 c0 -113 -31 -175 -136 -277 -77 -75 -108 -121 -127 -193 l-11 -39 27 7 c51 12 214 95 277 140 148 105 238 262 252 435 10 127 -2 181 -49 235 -58 66 -158 133 -239 162 -67 23 -207 38 -254 27z"/>
                  <path d="M2341 2599 c-46 -360 -229 -594 -540 -691 -70 -22 -75 -25 -93 -67 -48 -112 -88 -368 -73 -466 l6 -45 49 0 c123 0 280 -50 370 -119 43 -33 45 -34 90 -22 25 7 86 32 135 56 252 123 386 264 459 484 114 345 79 622 -101 792 -69 64 -144 111 -239 147 l-52 19 -11 -88z m177 -53 c155 -163 239 -396 212 -585 -11 -76 -40 -172 -70 -230 -35 -68 -121 -181 -193 -254 -79 -79 -245 -213 -254 -204 -9 10 40 82 117 171 105 122 180 245 221 362 31 92 33 108 34 229 0 101 -4 142 -19 185 -24 71 -70 164 -103 207 -20 26 -27 49 -31 103 -4 65 -3 70 15 70 11 0 43 -24 71 -54z"/>
                  <path d="M1725 2266 c-52 -22 -153 -197 -213 -367 -57 -162 -115 -425 -147 -661 -4 -32 -11 -48 -21 -48 -19 0 -19 96 1 288 35 329 116 585 222 703 33 36 35 41 20 56 -43 43 -122 -41 -217 -232 -89 -179 -167 -444 -206 -695 -17 -111 -24 -123 -56 -97 -16 11 -15 22 2 137 49 327 154 619 295 815 51 70 53 75 35 85 -22 12 -60 -4 -118 -48 -67 -51 -169 -222 -224 -377 -32 -89 -92 -322 -113 -442 -10 -54 -22 -93 -29 -93 -6 0 -39 25 -73 55 l-61 55 14 -62 c30 -126 91 -191 218 -231 98 -30 249 -29 361 2 57 16 120 24 218 28 162 7 230 -4 338 -53 186 -83 401 -98 530 -37 79 38 136 87 168 144 l25 44 -45 -36 c-114 -92 -208 -124 -343 -116 -108 6 -199 36 -326 108 -119 67 -177 82 -307 77 l-106 -3 6 116 c20 371 104 672 223 799 49 53 52 60 26 83 -22 20 -56 21 -97 3z"/>
                  <path d="M989 2063 c-17 -45 -23 -156 -10 -195 7 -20 22 -40 35 -46 22 -10 25 -8 30 21 4 18 17 58 30 89 l23 57 -24 23 c-14 13 -32 35 -41 51 -9 15 -20 27 -24 27 -5 0 -13 -12 -19 -27z"/>
                  <path d="M831 1122 c22 -116 90 -197 206 -243 50 -20 76 -23 188 -23 107 0 143 4 205 23 54 16 111 24 202 28 151 7 222 -5 328 -52 142 -64 301 -92 422 -75 131 18 239 90 302 201 l20 34 -36 -31 c-113 -99 -204 -136 -333 -136 -112 0 -214 31 -335 100 -130 75 -175 87 -310 86 -102 0 -124 -4 -200 -31 -164 -60 -269 -65 -395 -19 -86 31 -144 67 -213 131 l-59 55 8 -48z"/>
                  <path d="M832 917 c14 -74 31 -110 77 -160 105 -113 296 -140 546 -76 71 18 118 23 220 23 139 1 203 -12 296 -59 122 -62 341 -91 459 -61 111 29 206 101 252 190 l21 40 -57 -51 c-124 -113 -274 -148 -448 -104 -75 19 -167 61 -237 106 -70 46 -158 68 -266 68 -91 1 -110 -3 -205 -37 -121 -43 -238 -56 -331 -37 -105 22 -219 88 -305 177 l-31 32 9 -51z"/>
                  </g>
              </svg> 
        case 'Cancer':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 346.000000 346.000000">
               <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
               fill="white" stroke="none">
               <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
               <path d="M2035 2943 c25 -57 79 -220 97 -292 l21 -85 21 42 c11 23 23 42 27 42 13 0 39 -93 46 -162 3 -37 9 -68 14 -68 4 0 22 12 39 27 42 35 53 19 46 -72 -4 -62 -3 -68 9 -51 23 31 30 11 29 -84 0 -72 2 -87 14 -82 39 14 36 -70 -5 -151 -13 -26 -27 -46 -31 -43 -5 3 -19 51 -32 107 l-24 101 -15 -31 c-8 -17 -18 -31 -22 -31 -4 0 -10 28 -14 63 -3 34 -9 75 -12 91 l-5 29 -20 -32 c-23 -36 -38 -40 -38 -9 0 12 -5 51 -11 87 l-12 65 -19 -38 c-19 -38 -38 -40 -38 -5 0 28 -71 180 -81 173 -15 -9 -10 -253 6 -327 38 -178 161 -336 326 -416 47 -22 93 -41 102 -41 23 0 44 35 69 110 38 120 25 370 -31 593 -10 37 -14 67 -10 67 16 0 64 -68 95 -135 69 -146 81 -340 34 -535 -22 -88 -20 -187 4 -202 30 -20 45 -7 74 70 68 176 87 387 49 532 -41 154 -146 322 -276 441 -102 94 -295 217 -408 260 -22 8 -24 7 -18 -8z"/>
               <path d="M1400 2894 c-41 -13 -130 -52 -198 -85 -453 -224 -639 -586 -532 -1037 21 -90 23 -93 48 -90 24 3 29 10 43 64 13 48 14 73 7 120 -13 79 -4 284 16 359 25 94 89 215 140 264 25 25 48 42 51 40 3 -3 -6 -34 -20 -69 -36 -92 -81 -253 -95 -342 -17 -103 -9 -261 15 -310 22 -44 36 -47 123 -19 190 59 331 199 396 391 25 75 46 208 46 285 l-1 40 -24 -30 c-14 -16 -42 -63 -62 -104 l-38 -73 -16 37 -17 38 -21 -67 c-12 -38 -21 -74 -21 -82 0 -28 -20 -13 -37 28 l-19 41 -18 -91 c-10 -51 -23 -92 -28 -92 -4 0 -13 12 -19 28 -11 26 -12 24 -45 -58 -18 -47 -33 -91 -34 -98 0 -27 -25 -4 -42 39 -18 47 -23 116 -10 137 5 7 16 10 25 6 14 -5 17 0 17 29 0 37 17 137 25 145 2 3 11 0 19 -7 11 -10 14 -1 18 49 6 79 19 92 56 57 15 -15 33 -27 39 -27 6 0 14 23 17 52 7 54 26 106 53 147 l16 25 17 -45 c18 -43 18 -43 28 -19 39 99 109 254 134 298 16 28 28 52 26 52 -2 0 -37 -12 -78 -26z"/>
               <path d="M1451 1924 c-27 -35 -27 -78 1 -106 20 -20 23 -35 27 -130 3 -89 6 -108 20 -110 9 -2 23 6 32 19 13 20 14 34 4 108 l-12 86 27 14 c31 16 40 33 40 74 0 66 -98 97 -139 45z"/>
               <path d="M1901 1919 c-55 -22 -69 -89 -26 -131 l24 -25 -30 -79 c-25 -65 -28 -85 -20 -107 6 -15 17 -27 25 -27 14 0 21 17 68 183 6 20 15 37 19 37 19 0 49 46 49 75 0 30 -30 75 -50 75 -4 0 -14 2 -22 4 -7 3 -24 0 -37 -5z"/>
               <path d="M779 1632 c-15 -13 -22 -30 -22 -52 0 -38 23 -60 63 -60 55 0 80 70 40 110 -25 25 -51 25 -81 2z"/>
               <path d="M1100 1612 c-87 -29 -209 -100 -225 -132 -13 -25 -13 -30 0 -40 12 -10 26 -8 73 13 90 40 195 78 200 72 3 -3 -5 -13 -19 -22 -29 -20 -152 -143 -197 -197 -38 -47 -39 -65 -7 -134 63 -135 185 -282 282 -339 27 -16 42 -31 39 -39 -13 -35 81 -47 199 -26 39 7 133 18 209 25 136 13 142 13 245 -12 153 -35 157 -35 236 35 97 85 237 245 306 348 56 85 58 89 44 116 -21 41 -105 111 -203 169 -118 69 -124 73 -87 66 30 -7 167 -57 236 -88 46 -21 67 -9 53 30 -7 20 -31 40 -84 70 -95 55 -185 83 -264 83 -81 0 -129 -14 -182 -55 -120 -92 -296 -84 -527 22 -92 42 -110 47 -187 50 -62 2 -100 -2 -140 -15z m1201 -267 c75 -39 76 -43 30 -132 -51 -98 -125 -197 -217 -291 l-71 -72 -37 15 c-105 44 -342 62 -560 42 -81 -8 -88 -7 -76 7 20 25 128 73 195 87 83 17 243 15 348 -5 48 -9 93 -19 101 -22 8 -3 56 49 130 140 64 79 116 148 116 153 0 5 -25 33 -55 63 -30 30 -55 58 -55 62 0 10 97 -20 151 -47z"/>
               <path d="M2487 1612 c-33 -37 -16 -92 34 -107 29 -9 73 24 77 58 7 54 -74 90 -111 49z"/>
               <path d="M2570 1223 c-23 -9 -60 -45 -60 -59 0 -7 19 -18 43 -24 79 -19 130 -52 153 -97 l22 -43 16 35 c27 56 -3 116 -82 166 -45 29 -63 33 -92 22z"/>
               <path d="M685 1172 c-50 -27 -95 -81 -95 -113 0 -10 5 -30 10 -45 l10 -26 23 33 c32 48 80 77 142 84 74 10 86 26 47 65 -38 38 -71 38 -137 2z"/>
               <path d="M2443 1045 c-30 -21 -21 -38 36 -68 26 -15 55 -34 64 -44 24 -27 49 -93 42 -111 -5 -13 -3 -13 17 0 54 36 41 131 -27 203 -26 28 -40 35 -71 35 -22 0 -49 -7 -61 -15z"/>
               <path d="M809 1030 c-81 -43 -122 -125 -85 -175 12 -17 13 -17 20 9 9 37 78 84 134 91 71 10 82 15 82 35 0 27 -47 60 -84 60 -17 -1 -47 -9 -67 -20z"/>
               <path d="M2344 919 c-59 -17 -49 -45 35 -91 39 -22 73 -76 67 -108 -4 -24 -3 -23 25 8 32 36 35 57 15 105 -14 35 -79 98 -99 96 -7 0 -26 -5 -43 -10z"/>
               <path d="M928 855 c-50 -36 -65 -67 -54 -110 l6 -25 38 39 c31 32 48 41 100 51 34 7 65 17 68 22 3 5 -4 20 -17 34 -34 36 -82 32 -141 -11z"/>
               <path d="M2179 737 c-19 -12 -34 -27 -33 -32 1 -6 32 -26 69 -45 37 -19 73 -46 80 -59 l13 -25 16 32 c9 17 16 38 16 46 0 31 -83 106 -118 106 -5 0 -24 -10 -43 -23z"/>
               <path d="M1095 738 c-47 -25 -85 -72 -85 -102 0 -43 16 -66 24 -38 12 37 63 71 117 78 61 8 70 21 35 51 -29 25 -59 29 -91 11z"/>
               <path d="M1476 721 c-19 -21 -12 -48 15 -56 42 -14 68 44 30 65 -25 13 -25 13 -45 -9z"/>
               <path d="M1821 712 c-20 -38 31 -71 59 -37 6 8 9 23 5 35 -8 25 -51 27 -64 2z"/>
               <path d="M1855 623 c-32 -8 -45 -23 -45 -52 0 -40 18 -51 80 -51 100 0 158 63 90 97 -25 13 -86 15 -125 6z"/>
               <path d="M1420 582 c-39 -15 -45 -21 -45 -47 0 -39 35 -58 90 -49 81 13 113 43 95 89 -8 21 -16 25 -52 24 -24 0 -63 -8 -88 -17z"/>
               </g>
           </svg>
        case 'Capricorn':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 346.000000 346.000000">
                  <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                  fill="white" stroke="none">
                  <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                  <path d="M1841 2893 c-70 -71 -142 -147 -159 -170 l-32 -41 35 -12 c19 -6 35 -15 35 -19 0 -5 -27 -14 -59 -20 -55 -11 -61 -15 -90 -60 -17 -26 -31 -50 -31 -53 0 -3 24 -12 53 -18 107 -25 100 -38 -22 -42 l-74 -3 -43 -85 c-44 -86 -87 -190 -82 -196 2 -1 21 1 43 6 22 4 65 13 96 20 l57 11 47 107 c74 167 271 558 320 634 25 37 42 68 39 68 -3 0 -62 -57 -133 -127z"/>
                  <path d="M2345 2862 c-335 -216 -605 -458 -685 -613 l-19 -37 57 -49 c31 -27 63 -53 69 -57 17 -10 93 110 93 147 0 20 5 27 19 27 12 0 37 24 65 61 l45 62 -36 44 c-20 24 -33 46 -30 49 3 3 23 -4 46 -15 22 -12 46 -21 53 -21 7 1 31 24 53 53 75 97 245 285 322 356 43 39 76 70 73 70 -3 0 -59 -35 -125 -77z"/>
                  <path d="M1449 2121 l-117 -27 -77 -69 c-306 -274 -506 -587 -509 -797 -1 -74 0 -78 32 -111 40 -41 95 -59 167 -56 59 3 77 -7 102 -55 24 -46 18 -102 -16 -157 l-30 -49 27 0 c44 1 106 34 144 79 33 39 37 41 47 24 9 -16 12 -16 21 -3 24 37 33 124 21 217 -6 50 -13 98 -17 106 -10 26 54 54 138 61 88 8 210 38 272 67 48 22 120 89 144 134 10 19 16 54 16 95 1 64 -19 120 -71 200 -12 20 -14 38 -9 83 4 31 10 57 14 57 4 -1 16 -20 28 -43 24 -49 76 -83 144 -92 87 -12 267 42 294 88 15 25 -11 43 -77 51 -84 10 -219 54 -264 85 l-41 29 -22 -23 c-27 -29 -27 -29 -144 64 -49 39 -91 71 -95 70 -3 0 -58 -13 -122 -28z m121 -263 c0 -36 -7 -50 -38 -85 -33 -37 -43 -42 -94 -47 -72 -8 -74 3 -16 69 39 44 120 105 140 105 4 0 8 -19 8 -42z m-605 -707 c-21 -41 -58 -65 -90 -57 -27 7 -99 65 -90 74 2 3 35 4 72 3 41 -1 78 4 93 12 31 16 36 7 15 -32z"/>
                  <path d="M1916 1598 c-49 -229 -176 -366 -354 -385 -71 -7 -69 -5 -181 -171 l-43 -64 59 7 58 7 -52 -46 c-87 -78 -212 -243 -332 -439 -43 -71 -41 -73 19 -27 89 69 211 126 330 155 85 21 329 29 443 15 152 -18 263 -67 307 -135 16 -24 18 -25 25 -8 14 35 18 317 6 423 -24 202 -108 464 -207 645 -24 44 -47 86 -52 93 -5 8 -15 -17 -26 -70z"/>
                  </g>
                  </svg>
        case 'Gemini':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 346.000000 346.000000">
                      <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                      fill="white" stroke="none">
                      <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                      <path d="M902 2638 c-42 -69 -191 -458 -199 -520 -5 -35 -2 -39 42 -70 26 -18 54 -38 62 -44 13 -9 11 -19 -17 -75 -39 -77 -163 -300 -196 -351 -45 -70 -36 -77 114 -88 57 -4 106 -10 109 -13 3 -4 -29 -96 -72 -206 -85 -221 -87 -233 -31 -276 70 -53 135 -68 329 -73 l177 -4 0 -164 0 -164 200 0 200 0 0 293 0 292 -65 92 c-36 51 -65 97 -65 102 0 6 16 11 35 11 115 0 195 114 195 279 0 52 -4 66 -25 86 -15 16 -36 25 -55 25 -32 0 -101 -30 -133 -58 -9 -8 -20 -12 -23 -9 -4 3 -1 66 5 139 10 114 9 140 -4 184 -33 111 -139 175 -330 199 -155 20 -247 124 -247 279 0 39 5 91 12 115 14 54 8 61 -18 19z m281 -611 c16 -11 15 -15 -15 -43 -45 -43 -127 -104 -140 -104 -18 0 -129 113 -126 129 4 22 40 29 159 30 70 1 111 -3 122 -12z"/>
                      <path d="M2565 2653 c4 -10 12 -50 18 -89 13 -88 -1 -160 -43 -227 -37 -59 -123 -103 -234 -118 -44 -6 -103 -19 -132 -30 -99 -37 -171 -125 -176 -214 -1 -16 2 -86 6 -155 l8 -124 -23 17 c-41 30 -103 57 -132 57 -18 0 -39 -11 -58 -29 -24 -25 -29 -38 -29 -78 0 -66 25 -160 55 -205 29 -44 90 -78 138 -78 18 0 37 -3 40 -6 3 -4 -23 -49 -58 -101 l-65 -95 0 -294 0 -294 195 0 195 0 0 165 0 165 158 0 c122 0 172 4 223 18 89 25 154 64 167 100 11 33 0 68 -92 296 -30 77 -54 141 -52 143 2 2 54 8 117 13 133 11 153 22 122 67 -30 41 -233 417 -233 430 0 6 25 28 55 49 31 21 58 48 61 61 4 12 -3 46 -15 75 -11 29 -46 121 -77 203 -66 176 -120 295 -135 295 -7 0 -8 -7 -4 -17z m-1 -623 c36 -13 33 -30 -19 -88 -66 -73 -73 -74 -162 -6 -80 61 -94 81 -72 95 19 12 221 11 253 -1z"/>
                      </g>
                  </svg>
        case 'Leo':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 346.000000 346.000000">
                      <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                      fill="white" stroke="none">
                      <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                      <path d="M1775 2895 c-91 -20 -186 -61 -266 -116 -84 -58 -91 -55 -87 34 3 65 -4 70 -56 37 -57 -34 -123 -132 -169 -247 -9 -24 -21 -43 -27 -43 -14 0 -32 38 -45 94 l-11 49 -32 -49 c-33 -52 -65 -155 -55 -179 8 -21 -14 -18 -48 6 l-29 20 0 -66 c0 -75 25 -155 70 -228 l30 -48 -56 -72 c-81 -103 -98 -141 -98 -212 1 -59 0 -62 -77 -172 -122 -173 -139 -203 -139 -241 0 -45 31 -94 74 -117 19 -10 39 -29 45 -42 7 -15 25 -27 54 -34 l42 -12 -3 -69 c-5 -82 9 -109 66 -126 84 -25 142 -10 215 57 24 23 51 41 58 41 8 0 31 11 51 25 31 21 49 25 109 25 86 0 146 24 184 74 33 43 35 140 4 218 -24 62 -94 163 -142 205 l-33 29 47 53 c26 29 62 83 81 119 30 60 33 75 33 152 0 98 -22 152 -76 191 -50 36 -140 37 -221 4 -11 -4 -18 -3 -18 3 0 6 30 25 68 43 121 58 221 42 280 -45 34 -51 55 -127 56 -201 1 -61 -4 -80 -32 -137 -18 -37 -52 -88 -76 -115 l-43 -48 45 -55 c81 -98 149 -273 167 -426 l7 -61 -49 -18 c-139 -52 -246 -118 -346 -214 -102 -98 -162 -210 -181 -340 l-7 -44 46 61 c63 87 187 181 337 257 99 51 317 145 335 145 2 0 3 -8 3 -17 0 -31 -50 -126 -91 -175 l-39 -47 52 15 c319 92 500 247 586 499 25 75 27 91 27 260 0 165 -2 189 -28 286 -15 58 -38 134 -52 168 -31 81 -14 75 47 -15 120 -177 177 -452 149 -719 -7 -60 -14 -119 -17 -130 -12 -44 80 98 136 210 80 159 110 277 110 430 0 216 -67 384 -229 573 -44 52 -176 165 -203 175 -7 2 -10 8 -6 12 10 11 117 -19 177 -49 25 -13 74 -45 108 -70 53 -40 85 -74 169 -181 23 -29 -42 89 -94 170 -24 39 -79 104 -121 145 -123 121 -233 167 -382 158 -43 -3 -79 -2 -79 1 0 11 64 49 93 56 45 12 40 22 -26 48 -118 47 -251 59 -372 32z m272 -385 c47 -26 78 -70 95 -133 11 -44 11 -52 -7 -84 -30 -55 -66 -76 -135 -81 -33 -2 -60 0 -60 4 1 5 15 18 32 29 47 30 73 70 73 115 0 80 -82 140 -189 140 -68 0 -66 12 4 29 63 14 143 6 187 -19z m-737 -560 c0 -22 -30 -68 -60 -94 -22 -18 -43 -26 -70 -26 -77 0 -89 20 -37 62 71 56 167 89 167 58z m-451 -437 c-1 -33 -35 -117 -51 -127 -25 -15 -67 8 -82 46 -8 18 -12 37 -10 43 4 10 104 51 132 54 6 0 12 -7 11 -16z"/>
                      </g>
                  </svg>
        case 'Libra':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 346.000000 346.000000">
                      <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                      fill="white" stroke="none">
                      <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                      <path d="M786 2490 c-19 -17 -45 -51 -57 -76 -56 -116 27 -298 152 -335 65 -20 152 0 283 65 116 58 219 86 314 86 45 0 49 -3 94 -58 26 -31 53 -65 61 -76 12 -17 18 -12 73 58 58 75 60 75 106 76 92 0 199 -28 307 -81 200 -99 295 -104 379 -19 82 81 111 216 63 293 -26 42 -86 97 -106 97 -53 0 -98 -63 -60 -84 39 -23 85 -69 91 -91 7 -30 -16 -75 -62 -115 -36 -31 -39 -32 -108 -27 -88 6 -135 22 -306 103 -74 35 -159 69 -188 77 -40 10 -57 20 -71 43 -22 36 -37 48 -77 62 -43 14 -104 -14 -132 -60 -17 -28 -31 -37 -73 -46 -29 -7 -108 -39 -176 -72 -149 -72 -229 -100 -305 -107 -73 -7 -112 9 -149 60 -49 67 -36 109 55 170 32 22 33 31 1 62 -32 33 -67 31 -109 -5z"/>
                      <path d="M1596 1934 c-26 -36 -50 -73 -53 -81 -3 -8 11 -39 32 -69 33 -47 42 -54 71 -54 33 0 50 16 97 90 16 24 15 27 -29 90 -70 101 -63 100 -118 24z"/>
                      <path d="M819 1828 c-7 -13 -38 -72 -69 -133 -31 -60 -96 -186 -145 -278 -86 -166 -87 -169 -76 -208 36 -118 218 -194 405 -168 134 18 232 80 261 165 7 22 11 42 8 46 -2 5 -139 8 -304 8 -164 0 -299 1 -299 3 0 9 88 204 155 347 94 197 110 240 90 240 -8 0 -20 -10 -26 -22z"/>
                      <path d="M2326 1799 c-70 -160 -158 -380 -153 -385 3 -3 14 -1 25 5 20 11 41 62 122 299 43 130 47 173 6 81z"/>
                      <path d="M2440 1840 c0 -11 76 -182 193 -435 l65 -140 -304 -3 -304 -2 0 -24 c0 -13 15 -45 33 -72 122 -179 536 -168 633 18 24 46 16 77 -47 197 -34 64 -100 190 -147 281 -46 91 -89 171 -94 178 -13 15 -28 16 -28 2z"/>
                      <path d="M941 1830 c0 -8 30 -102 68 -209 54 -158 71 -196 89 -202 17 -6 22 -5 22 7 0 17 -158 393 -172 409 -5 5 -8 3 -7 -5z"/>
                      <path d="M1618 1381 l-3 -266 -35 -3 c-19 -1 -77 -22 -127 -45 l-93 -42 0 -52 0 -53 284 0 284 0 4 51 3 51 -90 44 c-58 28 -105 44 -128 44 l-37 0 0 264 0 264 -30 4 -30 4 -2 -265z"/>
                      <path d="M1037 833 c-4 -3 -7 -46 -7 -95 l0 -88 615 0 615 0 0 95 0 95 -608 0 c-335 0 -612 -3 -615 -7z"/>
                      </g>
                  </svg>         
        case 'Pisces':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 346.000000 346.000000">
                      <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                      fill="white" stroke="none">
                      <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                      <path d="M1046 2992 c-99 -74 -163 -177 -180 -289 l-6 -42 117 -3 118 -3 -123 -44 -122 -43 -40 -62 c-81 -123 -128 -288 -136 -481 l-5 -110 34 63 c98 176 252 321 400 377 37 14 46 22 41 34 -57 135 -29 286 76 404 23 26 37 47 31 47 -26 0 -101 84 -122 136 -13 30 -25 54 -28 54 -3 0 -28 -17 -55 -38z"/>
                      <path d="M1451 2930 c-68 -18 -90 -31 -91 -52 0 -11 16 -9 85 7 169 41 359 -10 498 -134 l51 -46 -34 63 c-39 74 -83 115 -160 151 -76 35 -239 41 -349 11z"/>
                      <path d="M1691 2796 c-13 -16 -12 -17 81 -70 39 -22 49 -34 58 -67 13 -46 9 -61 -32 -143 -36 -70 -117 -160 -173 -189 -55 -29 -153 -29 -217 0 -60 27 -140 102 -162 150 l-17 38 65 143 c36 78 61 142 56 142 -27 0 -98 -71 -125 -125 -27 -54 -30 -69 -30 -155 0 -101 13 -139 73 -218 29 -38 150 -101 211 -109 23 -3 43 -8 45 -12 2 -3 -2 -46 -9 -96 -7 -49 -10 -92 -8 -95 10 -11 100 50 146 100 29 31 78 73 110 94 55 36 97 79 97 97 0 5 -16 9 -35 9 -19 0 -35 3 -35 6 0 3 22 26 50 51 52 47 100 131 122 216 l13 48 -45 37 c-25 19 -72 64 -105 99 -49 51 -66 63 -91 63 -17 0 -37 -6 -43 -14z"/>
                      <path d="M2743 2751 c-79 -36 -156 -140 -197 -267 l-17 -51 -112 -6 c-130 -7 -193 -24 -262 -69 l-50 -33 95 -6 c170 -12 299 -60 390 -145 26 -24 60 -47 77 -50 l30 -6 6 104 c9 147 44 338 79 435 16 46 34 86 39 89 5 3 9 9 9 15 0 15 -42 10 -87 -10z"/>
                      <path d="M1374 2696 c-19 -19 -34 -41 -34 -50 0 -19 7 -20 40 -1 62 36 59 -3 -4 -46 -42 -28 -45 -45 -11 -61 65 -29 144 101 101 167 -22 34 -52 31 -92 -9z"/>
                      <path d="M1068 2256 c-118 -48 -189 -108 -262 -218 -38 -57 -79 -159 -90 -223 l-7 -40 56 62 c98 109 224 196 337 232 34 11 36 14 28 37 -6 14 -10 30 -10 35 0 5 56 9 131 9 92 0 130 3 127 11 -2 6 -49 29 -103 51 -55 22 -107 46 -116 54 -20 18 -24 18 -91 -10z"/>
                      <path d="M2736 2133 c-35 -116 -104 -239 -178 -318 -66 -71 -70 -77 -54 -94 9 -9 16 -22 16 -27 0 -5 -51 -29 -112 -54 -61 -25 -113 -51 -116 -58 -3 -9 26 -12 120 -12 77 0 128 -4 135 -11 16 -16 121 93 159 166 58 111 69 158 68 295 0 69 -5 135 -10 148 -9 21 -12 18 -28 -35z"/>
                      <path d="M2842 1988 c-23 -183 -92 -344 -195 -455 l-59 -63 41 -46 c80 -91 106 -233 64 -356 -21 -63 -21 -68 12 -68 35 0 123 -50 154 -87 l29 -35 27 40 c65 98 92 247 64 357 l-11 43 -100 -44 c-56 -24 -106 -44 -111 -44 -6 0 33 41 86 90 91 86 96 93 108 148 7 36 10 102 6 182 -6 129 -28 233 -79 369 l-24 64 -12 -95z"/>
                      <path d="M772 1768 c-16 -29 -44 -80 -62 -113 -64 -115 -154 -240 -225 -312 -77 -79 -76 -83 10 -83 94 0 158 32 253 126 l83 82 57 -25 c124 -55 262 -76 332 -51 l34 11 -52 23 c-144 63 -282 187 -332 296 -15 35 -37 71 -48 81 -19 18 -20 17 -50 -35z"/>
                      <path d="M2076 1647 c-29 -38 -76 -135 -76 -158 0 -8 -12 -31 -26 -50 -36 -48 -74 -125 -74 -150 0 -21 14 -22 64 -3 11 4 7 -11 -16 -56 -27 -51 -33 -75 -36 -148 -2 -48 0 -102 5 -121 8 -32 14 -36 103 -66 52 -18 115 -41 141 -52 45 -21 45 -21 72 -1 15 11 27 25 27 32 0 13 -61 26 -121 26 -20 0 -36 11 -58 37 -29 35 -31 44 -31 113 0 150 55 273 147 327 43 25 57 28 138 27 100 -1 142 -14 200 -65 l35 -31 0 -156 0 -156 25 34 c37 50 48 95 43 174 -6 93 -43 170 -109 230 -72 64 -126 80 -260 74 l-107 -6 -23 89 c-13 49 -27 89 -31 89 -4 0 -18 -15 -32 -33z"/>
                      <path d="M2420 1215 c-19 -20 -24 -37 -24 -78 -2 -131 109 -149 121 -20 l4 48 -31 -33 c-17 -18 -35 -32 -40 -30 -5 2 3 29 17 61 22 49 24 60 12 68 -22 14 -34 11 -59 -16z"/>
                      <path d="M2555 911 c-35 -35 -138 -91 -204 -111 -110 -34 -256 -25 -376 24 l-40 16 40 -35 c95 -83 207 -111 324 -81 123 31 284 131 293 180 6 31 -10 34 -37 7z"/>
                      <path d="M1783 3 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
                      </g>
                  </svg>   
        case 'Sagittarius':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 346.000000 346.000000">
                      <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                      fill="white" stroke="none">
                      <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                      <path d="M1120 2829 c-63 -25 -76 -65 -36 -113 l23 -30 -78 -235 c-44 -129 -73 -229 -65 -221 7 8 55 110 106 226 l92 212 37 2 c20 1 97 2 171 2 119 1 146 -2 225 -26 117 -35 234 -92 315 -155 75 -57 120 -70 174 -47 40 17 56 46 56 102 0 77 -52 106 -64 36 -7 -47 -46 -92 -79 -92 -14 0 -37 15 -58 36 -43 45 -139 114 -205 147 -138 70 -276 94 -435 76 -88 -10 -110 -10 -135 2 -32 15 -43 51 -24 75 14 16 14 16 -20 3z"/>
                      <path d="M2678 2541 c-109 -25 -198 -49 -198 -53 0 -3 16 -16 35 -28 19 -12 35 -25 34 -28 0 -6 -258 -140 -287 -150 -7 -2 -17 9 -23 25 -5 15 -21 47 -35 70 l-24 42 -46 -16 c-41 -14 -44 -17 -30 -32 27 -29 57 -111 47 -129 -5 -9 -170 -97 -367 -196 -197 -100 -360 -186 -362 -192 -1 -6 8 -14 22 -16 18 -4 111 40 348 162 178 93 324 167 325 166 1 -1 -1 -21 -4 -45 -4 -31 -12 -45 -27 -51 -12 -5 -49 -20 -84 -33 -82 -32 -161 -80 -229 -139 -42 -38 -62 -48 -89 -48 -67 0 -165 -33 -307 -103 l-142 -70 48 -20 c31 -14 49 -28 52 -41 2 -12 6 -95 9 -186 5 -148 4 -170 -12 -200 -24 -43 -76 -71 -126 -68 -56 4 -79 39 -84 127 l-4 68 -137 -55 c-115 -47 -143 -62 -185 -104 -58 -58 -93 -78 -154 -87 l-45 -6 64 -52 c102 -84 170 -116 260 -121 67 -4 84 -1 132 21 30 14 73 43 94 64 30 30 51 42 84 47 115 18 206 95 270 229 35 75 43 85 91 111 77 42 153 110 198 176 41 60 55 70 98 70 53 0 108 40 231 171 86 91 126 127 131 117 4 -7 13 -37 21 -65 14 -58 19 -60 73 -38 40 17 40 16 27 65 -23 90 -23 88 10 106 30 15 31 18 27 69 -3 39 -12 62 -31 85 -17 20 -27 43 -27 64 0 17 -5 37 -11 43 -8 8 18 26 93 66 149 80 138 79 138 11 l0 -59 83 81 c45 44 116 112 158 152 42 40 74 72 70 71 -3 -1 -95 -22 -203 -48z"/>
                      <path d="M878 2009 c-81 -42 -147 -136 -148 -207 0 -19 7 -50 16 -68 13 -27 14 -40 6 -58 -10 -22 -7 -25 31 -40 23 -9 77 -36 120 -61 l77 -45 0 -63 0 -62 34 39 33 40 23 -32 23 -31 67 34 67 34 -23 98 c-13 54 -24 114 -24 134 l0 36 -45 -10 c-57 -13 -111 2 -150 43 -16 16 -26 32 -23 35 7 7 242 25 248 19 2 -3 7 -26 10 -52 9 -80 25 -48 28 58 l3 99 -48 25 c-116 58 -254 73 -325 35z"/>
                      <path d="M2330 1701 c-34 -35 -37 -45 -43 -113 -7 -85 -45 -221 -86 -312 -54 -120 -115 -195 -272 -342 l-27 -24 -228 80 c-125 45 -233 79 -240 78 -7 -2 90 -49 216 -106 l228 -104 6 -39 c4 -25 14 -44 30 -54 22 -15 27 -15 63 1 l38 16 -27 10 c-23 8 -28 16 -28 41 0 27 15 45 101 127 110 103 163 173 202 266 41 96 57 172 64 292 l6 113 30 16 c25 12 37 13 67 4 21 -6 43 -18 50 -26 10 -12 16 -13 26 -4 24 20 16 45 -26 83 -57 51 -100 50 -150 -3z"/>
                      <path d="M763 1552 l-32 -17 25 -33 c21 -27 23 -33 10 -38 -8 -3 -25 1 -38 10 -29 20 -82 20 -142 0 -40 -14 -50 -23 -71 -64 l-24 -48 30 15 c24 12 36 13 55 4 22 -10 24 -16 24 -79 0 -55 -4 -75 -21 -96 l-20 -26 55 0 c42 0 61 5 75 19 25 25 41 82 41 148 0 48 3 54 30 68 16 8 31 15 35 15 3 0 5 -25 5 -55 0 -69 13 -76 73 -41 40 24 45 31 51 73 13 94 11 99 -48 132 -61 36 -69 36 -113 13z"/>
                      <path d="M595 1000 c10 -63 51 -135 95 -165 56 -39 122 -55 224 -55 71 0 96 -4 113 -18 14 -11 37 -58 62 -130 l39 -114 6 119 c3 65 8 161 11 212 l5 95 -43 -41 c-38 -38 -49 -42 -122 -53 -178 -27 -309 24 -370 142 l-27 53 7 -45z"/>
                      <path d="M1390 988 c-22 -11 -57 -18 -92 -18 l-58 0 5 -87 c3 -49 7 -134 10 -191 4 -90 17 -142 35 -142 12 0 30 93 30 154 0 35 5 78 11 97 16 46 71 120 108 144 37 24 38 28 9 47 -20 12 -27 12 -58 -4z"/>
                      </g>
                  </svg>
        case 'Scorpio':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 346.000000 346.000000">
                          <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                          fill="white" stroke="none">
                          <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                          <path d="M2104 3001 c-18 -11 -43 -35 -58 -53 -81 -107 -73 -225 19 -279 19 -12 35 -24 35 -27 -1 -4 -22 -17 -48 -29 -28 -13 -55 -35 -66 -54 l-19 -32 80 5 c64 4 90 10 126 31 54 32 100 85 124 144 10 24 23 41 28 40 22 -8 44 -93 44 -177 1 -70 -5 -100 -30 -170 -17 -47 -33 -97 -36 -112 -5 -26 -3 -28 31 -28 49 0 67 21 99 117 63 190 57 345 -18 493 -61 121 -219 187 -311 131z"/>
                          <path d="M1832 2274 c-17 -11 -22 -25 -22 -56 0 -39 1 -40 18 -25 10 10 26 17 35 17 24 0 76 -69 85 -115 l8 -40 27 29 c39 42 37 94 -4 141 -55 62 -105 79 -147 49z"/>
                          <path d="M2187 2171 c-32 -33 -57 -69 -57 -80 0 -22 63 -61 98 -61 23 0 116 125 108 145 -4 11 -75 55 -89 55 -2 0 -29 -27 -60 -59z"/>
                          <path d="M1636 2159 c-25 -20 -33 -50 -20 -83 5 -14 9 -13 31 4 14 11 30 20 34 20 17 0 92 -75 104 -104 l13 -30 16 32 c35 69 13 120 -68 158 -63 29 -76 30 -110 3z"/>
                          <path d="M2395 2091 c-26 -11 -51 -40 -63 -71 l-10 -26 62 4 c44 2 70 -2 96 -15 31 -16 35 -23 35 -56 0 -31 3 -37 21 -37 64 0 91 85 44 135 -53 56 -138 86 -185 66z"/>
                          <path d="M1387 2032 c-18 -19 -22 -68 -9 -89 6 -9 12 -7 25 7 9 11 26 20 37 20 23 0 110 -76 110 -96 0 -27 20 -23 35 7 21 39 13 88 -18 116 -57 53 -148 70 -180 35z"/>
                          <path d="M1960 2000 c-41 -21 -103 -49 -138 -60 l-63 -22 80 -28 c103 -35 177 -83 210 -135 l27 -42 17 23 c30 40 98 206 95 233 -2 16 -16 34 -37 48 -51 34 -101 30 -191 -17z"/>
                          <path d="M2272 1914 c-18 -12 -52 -62 -52 -76 0 -2 22 -1 49 0 34 3 64 -3 96 -17 46 -20 47 -22 43 -61 -3 -38 -2 -40 24 -40 35 0 68 32 68 65 0 96 -153 182 -228 129z"/>
                          <path d="M1176 1899 c-27 -21 -35 -69 -16 -99 8 -12 13 -11 33 8 13 12 30 22 38 22 23 0 97 -68 118 -107 l17 -35 16 24 c48 75 10 157 -91 193 -58 20 -83 19 -115 -6z"/>
                          <path d="M1651 1864 c-19 -8 -58 -36 -86 -62 -28 -26 -104 -94 -168 -151 -126 -111 -147 -139 -147 -196 0 -40 1 -40 64 -19 127 43 317 -25 386 -138 l16 -27 86 63 c110 82 199 170 227 223 13 26 21 61 21 89 0 44 -4 51 -47 91 -52 49 -161 106 -243 128 -67 18 -67 18 -109 -1z m324 -256 c-17 -77 -87 -165 -186 -234 -38 -26 -42 -27 -51 -10 -25 44 -75 88 -136 118 -60 30 -76 33 -174 37 -59 2 -108 6 -108 10 0 14 74 32 150 38 84 6 156 -10 242 -53 72 -37 85 -33 174 51 43 41 83 74 87 75 5 0 5 -15 2 -32z"/>
                          <path d="M581 1794 c-61 -16 -191 -76 -176 -80 61 -18 211 -41 224 -35 9 5 36 12 61 16 54 8 155 -21 214 -61 30 -21 46 -25 73 -21 49 8 75 34 71 71 -3 27 -11 35 -68 63 -36 18 -92 39 -125 48 -73 19 -202 18 -274 -1z"/>
                          <path d="M2163 1708 c-11 -13 -25 -32 -31 -43 -9 -19 -7 -20 41 -20 30 -1 69 -9 92 -20 37 -19 40 -23 40 -63 0 -37 3 -42 23 -42 33 0 72 43 72 79 0 37 -45 92 -94 115 -51 24 -118 21 -143 -6z"/>
                          <path d="M1087 1632 c-23 -25 -22 -85 1 -105 25 -23 78 -21 102 3 26 26 26 81 2 103 -25 23 -85 22 -105 -1z"/>
                          <path d="M307 1578 c-11 -112 25 -275 81 -368 63 -103 204 -199 347 -235 73 -19 238 -38 288 -33 l37 3 -93 72 c-50 39 -117 96 -148 127 l-57 55 -6 -40 c-3 -21 -10 -39 -15 -39 -16 0 -87 58 -139 114 -57 60 -95 133 -90 169 3 18 10 12 44 -38 41 -59 125 -145 143 -145 5 0 5 17 -1 40 -6 23 -8 44 -4 47 16 16 225 -82 288 -135 l31 -26 -8 25 c-48 163 -180 319 -330 390 -105 50 -181 69 -281 69 l-81 0 -6 -52z"/>
                          <path d="M2088 1495 c-23 -13 -58 -61 -58 -79 0 -3 13 -3 29 0 41 9 138 -22 152 -47 6 -12 8 -34 5 -50 -5 -25 -3 -29 18 -29 33 0 65 29 71 65 16 85 -138 184 -217 140z"/>
                          <path d="M1192 1360 c-38 -16 -54 -84 -31 -133 19 -43 119 -87 119 -53 0 41 82 51 148 17 64 -33 65 -68 3 -100 l-36 -19 28 -7 c50 -13 154 -1 184 21 42 32 65 68 66 108 2 59 -13 65 -129 51 -89 -11 -158 12 -227 75 -56 51 -79 58 -125 40z"/>
                          <path d="M1882 1304 c-16 -11 -22 -25 -22 -53 0 -45 24 -71 66 -71 46 0 74 25 74 66 0 63 -65 95 -118 58z"/>
                          <path d="M2057 1212 c-10 -10 -17 -30 -17 -44 0 -26 34 -78 50 -78 5 0 34 -9 64 -20 72 -27 138 -87 165 -150 12 -27 25 -50 29 -50 5 0 38 -25 75 -55 37 -31 67 -50 67 -44 0 34 -36 143 -67 202 -45 86 -137 172 -237 221 -82 41 -106 44 -129 18z"/>
                          <path d="M1850 900 c-25 -5 -65 -17 -90 -26 -52 -20 -168 -92 -190 -119 -14 -18 -11 -18 91 -12 58 4 136 4 173 0 l67 -6 -37 -38 -38 -39 36 0 c45 0 196 26 271 47 32 9 61 13 65 9 20 -21 -124 -95 -244 -125 -98 -24 -242 -47 -250 -38 -4 3 -1 20 5 38 6 18 9 34 6 37 -5 5 -250 -56 -274 -68 -9 -5 16 -27 80 -69 243 -161 439 -206 611 -141 117 44 232 156 293 286 30 64 36 52 -65 123 -71 50 -181 105 -250 125 -68 20 -200 28 -260 16z"/>
                          </g>
                  </svg>
        case 'Taurus':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                       viewBox="0 0 346.000000 346.000000">
                      <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                          fill="white" stroke="none">
                          <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                          <path d="M1650 3042 c-180 -186 -224 -383 -144 -658 5 -17 0 -24 -26 -32 -27 -10 -30 -15 -24 -34 31 -101 104 -150 225 -149 38 0 69 -3 69 -6 0 -3 -16 -34 -35 -69 -21 -38 -35 -76 -35 -97 0 -21 14 -62 35 -101 31 -58 35 -75 35 -139 0 -122 -39 -225 -117 -308 -61 -65 -128 -107 -226 -139 l-81 -28 -31 -95 c-16 -52 -39 -105 -50 -117 -11 -12 -53 -45 -95 -74 -117 -83 -220 -202 -244 -283 l-7 -21 77 24 c152 48 301 40 429 -25 30 -15 55 -26 55 -24 0 2 -13 32 -29 67 -36 80 -89 162 -131 204 l-33 33 22 22 23 22 54 -60 c70 -77 168 -214 204 -285 32 -64 60 -152 60 -190 0 -27 0 -27 53 -19 125 20 279 133 375 276 56 82 133 239 167 338 16 44 32 84 36 89 10 12 106 -79 134 -129 47 -80 69 -180 70 -305 0 -63 -3 -135 -7 -160 l-8 -45 24 45 c65 123 79 295 37 445 -29 103 -85 222 -150 318 -28 42 -51 87 -51 101 0 33 -25 150 -45 212 -63 188 -225 393 -383 484 -62 35 -75 53 -47 64 8 3 15 11 15 19 0 7 12 24 26 38 37 35 64 92 64 138 0 32 -12 57 -66 136 -76 111 -137 234 -159 320 -19 75 -19 185 -1 230 8 19 12 35 8 35 -3 0 -36 -30 -72 -68z m40 -302 c20 -76 60 -161 111 -241 55 -85 58 -106 28 -155 -20 -33 -39 -37 -39 -10 0 7 -28 57 -62 111 -33 54 -70 126 -81 159 -26 82 -27 210 -2 291 l19 60 7 -85 c3 -47 12 -105 19 -130z m402 -827 c67 -83 102 -142 128 -218 32 -94 23 -96 -28 -5 -58 104 -101 167 -136 199 l-26 25 -40 -39 -39 -38 -69 13 c-61 11 -123 43 -110 55 3 3 40 -2 84 -11 l79 -16 45 50 c25 28 48 51 51 51 4 1 31 -29 61 -66z"/>
                          <path d="M1092 3034 c-60 -30 -107 -83 -150 -169 -49 -100 -66 -184 -67 -330 0 -121 2 -133 25 -175 14 -25 40 -54 57 -65 18 -11 32 -23 33 -26 0 -4 -51 -44 -112 -90 -62 -45 -119 -90 -126 -98 -19 -22 -9 -94 22 -165 14 -32 26 -70 26 -83 0 -51 -127 -252 -212 -335 -23 -22 -28 -36 -28 -73 0 -71 41 -131 118 -169 22 -11 32 -24 32 -40 0 -29 37 -64 81 -76 19 -6 85 -10 147 -10 106 0 114 2 137 24 13 13 29 43 35 67 7 24 18 55 25 69 16 30 120 90 156 90 45 0 143 39 194 78 93 70 147 177 165 330 11 88 11 90 -15 128 -24 34 -26 46 -22 94 3 30 8 63 12 73 6 14 2 17 -22 17 -126 0 -235 124 -216 246 5 30 3 32 -41 43 -24 6 -58 11 -74 11 -37 0 -104 48 -136 97 -40 61 -60 154 -52 253 5 81 44 249 66 291 14 25 4 24 -58 -7z m-18 -175 c-33 -129 -6 -307 60 -394 14 -18 26 -36 26 -39 0 -13 -69 -5 -83 8 -8 8 -25 52 -37 98 -39 142 -29 293 26 376 24 36 26 21 8 -49z m386 -917 c0 -35 -34 -85 -78 -116 -71 -49 -99 -26 -62 50 13 25 40 64 61 87 38 39 39 40 59 22 11 -10 20 -29 20 -43z m-660 -239 c0 -31 -59 -175 -93 -225 -49 -74 -55 -123 -19 -161 31 -33 27 -40 -10 -21 -76 38 -112 99 -88 145 7 12 39 54 72 93 33 39 74 94 91 123 29 48 47 65 47 46z m130 -308 c18 -22 4 -64 -25 -71 -13 -3 -29 -3 -34 1 -16 10 -13 46 6 67 20 22 36 23 53 3z"/>
                      </g>
                  </svg>      
        case 'Virgo':
            return <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                   viewBox="0 0 346.000000 346.000000">
                      <g transform="translate(0.000000,346.000000) scale(0.100000,-0.100000)"
                      fill="white" stroke="none">
                      <circle cx="1720" cy="1730" r="1690" width="100%" height="100%" fill={themeColor} strokeWidth="10"/>
                      <path d="M1864 2936 c-85 -43 -147 -100 -164 -150 -11 -35 -10 -38 47 -117 181 -251 234 -344 292 -511 47 -137 64 -230 64 -363 0 -135 -15 -213 -65 -333 -66 -161 -270 -322 -498 -394 -65 -20 -95 -23 -220 -22 -131 0 -153 3 -230 28 -126 41 -296 132 -353 189 -61 60 -100 143 -105 222 -3 51 1 69 25 115 26 51 31 55 66 58 48 4 73 -16 89 -70 10 -34 9 -42 -9 -66 -10 -15 -34 -34 -51 -43 l-32 -17 32 -6 c65 -12 131 24 165 87 32 63 -20 183 -94 213 -48 20 -151 17 -228 -7 -177 -54 -242 -222 -157 -401 57 -120 251 -298 422 -388 261 -135 580 -132 860 10 90 46 235 145 323 220 156 134 277 430 303 739 4 44 10 78 14 76 16 -10 22 -215 11 -325 -34 -322 -156 -554 -441 -841 -163 -164 -270 -228 -382 -229 -56 0 -78 18 -78 66 0 71 69 97 121 45 l29 -29 0 32 c0 64 -62 106 -158 106 -33 0 -48 -7 -82 -37 -74 -67 -96 -133 -64 -196 87 -171 326 -189 551 -41 191 126 499 508 572 708 37 104 64 209 87 336 22 121 22 356 1 460 -14 69 -14 91 -2 78 10 -9 46 -165 60 -258 44 -285 24 -582 -56 -825 -35 -106 -137 -319 -174 -363 -32 -38 -89 -59 -110 -42 -19 16 -19 41 -1 85 8 19 12 35 8 35 -4 0 -30 -16 -59 -35 -107 -72 -161 -174 -132 -251 15 -39 15 -39 74 -42 124 -7 246 90 331 263 41 84 48 94 110 134 265 175 394 354 354 495 -15 55 -63 114 -106 132 -19 8 -59 14 -90 14 l-56 0 6 43 c3 23 9 157 11 297 3 140 6 260 6 265 0 6 9 -8 20 -30 l18 -40 1 45 c0 68 -27 233 -56 340 -48 175 -129 319 -236 415 -114 102 -223 144 -398 152 l-115 5 -71 -36z"/>
                      <path d="M1513 2825 c-163 -44 -263 -134 -315 -283 -30 -86 -32 -274 -5 -397 28 -129 114 -249 243 -341 71 -51 89 -84 69 -130 -22 -51 -61 -79 -112 -79 -38 0 -49 5 -74 33 l-30 33 7 -28 c21 -85 72 -127 146 -119 26 3 65 14 87 25 73 38 69 121 -14 277 -101 188 -146 373 -126 518 19 135 73 243 155 312 96 80 144 171 100 188 -25 9 -74 6 -131 -9z"/>
                      <path d="M1606 2649 c-69 -81 -87 -209 -42 -305 13 -28 29 -55 35 -58 34 -22 -20 -50 -68 -36 -34 9 -36 8 -21 -21 6 -11 30 -24 56 -30 39 -10 44 -14 38 -33 -4 -11 -19 -35 -34 -53 -24 -27 -70 -127 -70 -152 0 -10 35 -21 67 -21 18 0 29 -13 53 -66 39 -87 106 -179 175 -242 58 -53 75 -62 75 -39 0 13 63 67 79 67 16 0 34 -80 27 -122 -4 -21 -4 -38 -2 -38 2 0 16 36 31 79 98 295 6 649 -255 977 -33 42 -67 89 -75 105 -9 16 -20 29 -24 29 -5 0 -25 -19 -45 -41z"/>
                      <path d="M1565 1437 c-147 -38 -233 -132 -235 -255 0 -55 14 -82 45 -82 34 0 178 47 261 85 125 57 254 160 293 233 12 22 12 22 -92 22 -57 0 -131 2 -163 5 -32 2 -81 -1 -109 -8z"/>
                      </g>
                  </svg>
        default:
            break;
    }
}


export function getProductSuggestions(gems,rudraksh,bracelet,productList){
    if(gems && rudraksh && bracelet && productList){
        let braceletProduct = bracelet.map((name) =>{
            return productList.braceletList.filter(value => name.toLowerCase() === value.name.toLowerCase());
        })
        let gemsProduct = productList.gemsList && productList.gemsList.filter(value => {return (value.name).toLowerCase() === gems.toLowerCase()});
        let rudrakshProduct = productList.rudrakshList && productList.rudrakshList.filter(value => {return (value.name).toLowerCase() === rudraksh.toLowerCase()});
        
        return {gemsProduct, rudrakshProduct,braceletProduct};

    }


}